<div class="flex flex-col flex-1 gap-10 bg-gray-100 min-h-screen justify-center
       md:gap-12">
  <div class="flex flex-col flex-1 max-w-xl lg:max-w-4xl">
    <div class="flex flex-col pb-4 gap-1 pt-3">
      <h2 class="text-3xl font-medium m-4 text-center">{{ 'MAKE_PAYMENT' | translate }}</h2>
    </div>

    <!--    Mensaje para cashback de los usuarios -->
    <div class="flex flex-col pb-4 gap-1 pt-3">
      <div
        *ngIf="paymentMethodConfiguration?.userMessage && paymentMethodConfiguration?.userMessage !== '' && !isLoadingPaymentMethodConfiguration"
        class="bg-gray-#D4E1EE flex flex-row p-17px font-Lato-Regular gap-3">
        <fa-icon class="relative flex justify-center items-center  text-gray-#5d5d5d rounded-full text-base w-6 h-6"
                 [icon]="faWarning"
        >
        </fa-icon>
        <p
          class="relative font-Lato-Regular text-gray-#5d5d5d text-16px leading-23px">{{ paymentMethodConfiguration?.userMessage }}</p>
      </div>
      <div class="relative w-full bg-gray-#D6D6D6 animate-pulse p-17px" *ngIf="isLoadingPaymentMethodConfiguration">
        <p class="h-12"></p>
      </div>
    </div>

    <hr class="my-4 border-gray-300"/>

    <!-- Resumen -->
    <div class="section-summary pb-4">
      <h2 class="text-lg font-semibold mb-4">{{ 'SUMMARY' | translate }}</h2>

      <div class="flex justify-between font-Lato-Regular" *ngIf="orderNumber">
        <span>{{ 'NO_ORDER' | translate }}:</span>
        <span>{{ orderNumber }}</span>
      </div>

      <div *ngIf="paymentBreakdown?.length !== 0" class="flex flex-col justify-between mt-4 font-Lato-Regular">
        <div class="flex flex-row justify-between" *ngFor="let adjust of paymentBreakdown">
          <span class="mb-2">{{ adjust?.Name | translate}}</span>
          <span>{{ adjust?.Amount | currency}}</span>
        </div>
      </div>

      <div class="flex justify-between text-xl font-bold mt-4 font-Lato-Regular">
        <span>{{ 'TOTAL' | translate }}:</span>
        <span>{{ total | currency}}</span>
      </div>
    </div>

    <hr class="my-4 border-gray-300"/>

    <p class="relative font-Lato-Regular leading-4 text-gray-#727272 mt-2 mb-2 text-center">
      {{ 'ALL_TRANSACTIONS_ARE_SECURE_AND_ENCRYPTED' | translate }}
    </p>

    <button class="relative font-bold flex justify-center items-center text-yellow-#FF6D03 bg-white border-2 border-yellow-#FF6D03 rounded-full hover:bg-orange-100
        font-Lato-Regular text-sm w-full h-10 mt-7 mb-2" (click)="logout()">
      <img class="relative w-7 fill-yellow-#FF6D03" src="../../../../../assets/imgs/icon-arrowleft.svg" alt="">
      {{'RETURN' | translate}}
    </button>

    <span class="text-blue-600 font-Lato-Regular text-[16px] hover:cursor-pointer text-center mt-2 mb-2" (click)="clearSelectedPayment()" *ngIf="selectedPaymentVariant !== null">
      {{ 'SELECT_ANOTHER_PAYMENT_METHOD' | translate }}
    </span>

    <div class="relative mt-3 w-full min-h-40px">
      <div *ngIf="isLoadingPaymentVariantFee || isLoadingPaymentMethodConfiguration"
           class="relative w-full flex flex-col gap-0.5">
        <div class="relative w-full grid
                    grid-cols-1 gap-5 items-center
                    animate-pulse" *ngIf="isLoadingPaymentVariantFee || true">
          <div
            class="relative bg-white flex flex-1 text-center justify-center items-center h-24 p-6 min-w-fit border border-gray-400 rounded"
            *ngFor="let pmc of [1,2,3,4]">

            <fa-icon class="absolute bg-white text-gray-300 left-1 top-0" [icon]="faCheckCircle"></fa-icon>
            <div class="relative w-full flex flex-row justify-center items-center">
            <span class="relative flex flex-col gap-1 items-center pt-3 w-20">
              <img class="relative w-8 h-8 bg-gray-300 rounded"/>
              <span class="relative bg-gray-300 font-Lato-Regular w-20 h-4 rounded"></span>
            </span>
            </div>
            <span
              class="absolute right-0 top-0 text-white text-center text-sm rounded-l px-1 bg-gray-300 w-12 h-5"></span>
          </div>
        </div>
      </div>

      <div class="relative w-full flex flex-col gap-0.5"
           *ngIf="!isLoadingPaymentVariantFee && !isLoadingPaymentMethodConfiguration">
        <div class="flex flex-col relative w-full gap-4">
          <ng-container *ngFor="let pmc of paymentMethodConfigurationVariants">
            <div class="relative bg-white flex flex-1 flex-col h-24 px-3 min-w-fit border border-gray-400 rounded"
                 *ngIf="selectedPaymentVariant == pmc?.paymentVariantId || selectedPaymentVariant == null ">
            <div class="relative bg-white min-w-fit cursor-pointer"
                 (click)="selectPaymentMethodConfigurationVariant(pmc, false)">
              <div class="relative w-full pl-5 pb-3">
            <span class="relative gap-0.5 flex items-center pt-3">
              <img class="relative w-8 h-8" *ngIf="!pmc.logo" src="./assets/imgs/icon-pay.svg"/>
              <img class="relative w-8 h-8"
                   *ngIf="pmc.logo"
                   [src]="pmc.logo.url"
              />
              <span class="relative pl-6 text-gray-#5d5d5d font-Lato-Regular">{{ pmc.title }}</span>
              <div class="flex items-center justify-center ml-auto mr-2">
                <fa-icon
                  *ngIf="pmc?.paymentVariantId === selectedPaymentVariant && pmc?.walletBehaviour === selectedPaymentVariantObject?.walletBehaviour"
                  class="bg-white text-green-#0D842D" [icon]="faCheckCircle"></fa-icon>
              </div>
            </span>
              </div>
              <span *ngIf="pmc.paymentVariantFee"
                    class="absolute right-0 top-0 text-white text-center text-sm rounded-l px-1"
                    [style.background-color]="pmc?.paymentVariantFeeLabelColor">{{ pmc?.paymentVariantFee }}</span>
            </div>

            <div *ngIf="pmc?.paymentVariantId === selectedPaymentVariant && pmc?.walletBehaviour === selectedPaymentVariantObject?.walletBehaviour"
                 class="relative w-full mt-2">
              <div class="bg-white border-t border-gray-400 rounded">
                <ng-container
                  *ngIf="(selectedPaymentVariant === paymentMethodEnum.WALLET || selectedPaymentVariant === paymentMethodEnum.TRANSACTION_AND_WALLET)
                  && selectedPaymentVariantObject?.walletBehaviour !== 3"
                  [ngTemplateOutlet]="KWallet"></ng-container>

                <ng-container
                  *ngIf="selectedPaymentVariant === paymentMethodEnum.TRANSACTION || selectedPaymentVariant === paymentMethodEnum.TRANSACTION_AND_WALLET"
                  [ngTemplateOutlet]="Cards"></ng-container>

                <ng-container
                  *ngIf="selectedPaymentVariant === paymentMethodEnum.WALLET || selectedPaymentVariant === paymentMethodEnum.TRANSACTION_AND_WALLET ||
                    selectedPaymentVariant === paymentMethodEnum.TRANSFER_MOVIL || selectedPaymentVariant === paymentMethodEnum.MI_TRANSFER
                    || selectedPaymentVariant === paymentMethodEnum.TROPI_PAY"
                  [ngTemplateOutlet]="BillingAddress"></ng-container>

                <ng-container *ngIf="selectedPaymentVariant === paymentMethodEnum.STAFF_PAYMENT">
                  <ng-container [ngTemplateOutlet]="StaffPayment"></ng-container>
                </ng-container>

                <ng-container *ngIf="selectedPaymentVariant === paymentMethodEnum.TRANSFER_MOVIL"
                              [ngTemplateOutlet]="TransferPayment"></ng-container>

                <ng-container *ngIf="selectedPaymentVariant === paymentMethodEnum.MI_TRANSFER"
                              [ngTemplateOutlet]="MiTransfer"></ng-container>

                <ng-container *ngIf="selectedPaymentVariant === paymentMethodEnum.PAYMENT_REQUEST"
                              [ngTemplateOutlet]="EmailPaymentRequest"></ng-container>

              </div>
            </div>
            <div *ngIf="pmc?.paymentVariantId === selectedPaymentVariant && pmc?.walletBehaviour === 3"
                 class="relative w-full mt-2">
              <ng-container
                *ngIf="selectedPaymentVariant === paymentMethodEnum.WALLET && selectedPaymentVariantObject?.walletBehaviour === 3"
                [ngTemplateOutlet]="MlcCup"></ng-container>
            </div>
            </div>
          </ng-container>
        </div>

        <div *ngIf="selectedPaymentVariantObject?.agreementQuery"
             class="flex flex-1 flex-col gap-2 items-start lg:items-center mt-4">
          <p class="relative text-justify font-Lato-Regular text-gray-#727272">
            {{ 'PAYMENT_VARIANT_AGREEMENT' | translate }}
            <span (click)="openAgreementModal()"
                  class="text-blue-#18396C cursor-pointer"> {{ 'AGREE_SCND' | translate }}</span>
          </p>
          <div *ngIf="!isLoadingAgreement" [innerHTML]="termsAndConditions"
               class="relative text-justify font-Lato-Regular text-gray-#727272 mt-2">
          </div>
        </div>
      </div>
    </div>

    <!-- Tenplate para el componente de StaffPayment -->
    <ng-template #StaffPayment>
      <div class="relative pb-7">
        <app-qr-staff-payment [formMarkAsTouched]="formMarkAsTouched" [form]="form"
                              [isSaving]="isLoadingSaving"></app-qr-staff-payment>
      </div>
    </ng-template>

    <!-- Tenplate para el wallet -->
    <ng-template #KWallet>
      <div class="relative pb-7" *ngIf="!isLoadingPaymentMethodConfiguration && !isLoadingPaymentVariantFee">
        <div [ngClass]="(isKWalletFundsApplied && formMarkAsTouched && walletAccountData.balance < total && selectedPaymentVariant === paymentMethodEnum.WALLET) ||
                        (formMarkAsTouched && !isKWalletFundsApplied) || insufficientBalance ? 'border-red-500' : 'border-gray-400'"
             class="flex flex-col justify-between items-center bg-white border rounded px-6 pb-4 mt-7">
          <img class="h-14 w-auto" src="../../../../../assets/imgs/kwallet-logo.png" alt="kwallet-logo">
          <div *ngIf="!isLoadingWalletAccount && walletAccountData && !isLoadingChangePaymentMethod"
               class="flex flex-col font-Lato-Regular h-full justify-center items-end gap-2">
            <div class="flex flex-col items-end gap-1" *ngIf="walletAccountData.isActive">
              <div class="flex flex-row leading-4">
                <p class="text-[#252525]">{{ 'CURRENT_BALANCE' | translate }}:</p>
                <!--                [ngClass]="{'line-through text-13px': isKWalletFundsApplied, 'font-semibold': !isKWalletFundsApplied}"-->
                <p class="ml-1" [ngClass]="{'line-through': isKWalletFundsApplied}">{{
                    walletAccountData.balance ||
                    0.00 | currency
                  }}</p>
              </div>

              <div *ngIf="selectedPaymentVariant === paymentMethodEnum.WALLET" class="flex flex-row leading-4 py-3">
                <p class="text-[#252525]">{{ 'BALANCE_APPLIED' | translate }}:</p>
                <p class="ml-1 font-semibold">{{ kwalletFounds | currency }}</p>
              </div>

              <div *ngIf="selectedPaymentVariant === paymentMethodEnum.TRANSACTION_AND_WALLET"
                   class="flex flex-col leading-4 gap-1" [formGroup]="formGroup">
                <div class="flex flex-row leading-4 gap-2">
                  <div class="flex flex-1 flex-col relative">
                    <!--                  bg-gray-#f2f2f2-->
                    <input placeholder="{{ 'BALANCE' | translate }}*" formControlName="wallet_amount" id="wallet_amount"
                           type="number" [min]="minAmount" [max]="maxAmount" [step]="minAmount" [ngClass]="{
                           'opacity-50 pointer-events-none': isKWalletFundsApplied,
                           'border-red-500': (walletAmount?.invalid && formMarkAsTouched) || (walletAmount?.invalid && (walletAmount?.dirty || walletAmount?.touched))
                         }" class="relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none
                          focus:ring-1 active:ring-1 h-40px rounded-3px px-4 text-base w-32 border border-[#BBBBBB]">
                  </div>

                  <div class="flex">
                    <button
                      (click)="applyFunds(!isKWalletFundsApplied, selectedPaymentVariant!)"
                      [disabled]="walletAmount?.invalid"
                      [ngClass]="{'opacity-50 pointer-events-none': walletAmount?.invalid, 'pl-1 pr-4': isKWalletFundsApplied, 'px-6': !isKWalletFundsApplied}"
                      class="flex bg-yellow-#FFC35D rounded-3px flex-row items-center font-Lato-Regular font-semibold
                            text-md justify-center h-40px">
                      <fa-icon *ngIf="isKWalletFundsApplied"
                               class="relative flex justify-center items-center rounded-full w-9 h-9 text-xl text-white"
                               [icon]="faCheck">
                      </fa-icon>
                      <div class="whitespace-nowrap" *ngIf="!isKWalletFundsApplied">{{ 'APPLY' | translate }}</div>
                      <div class="whitespace-nowrap" *ngIf="isKWalletFundsApplied">{{ 'APPLIED' | translate }}</div>
                    </button>
                  </div>
                </div>
                <div class="relative w-full flex flex-1 flex-col gap-0.5">
                  <div *ngIf="walletAmount?.invalid && (walletAmount?.dirty || walletAmount?.touched)"
                       class="text-sm text-red-500 text-11px"
                  >
                    <p *ngIf="walletAmount?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
                    <p *ngIf="walletAmount?.hasError('min')">{{ 'MIN_VALIDATION' | translate: {min: minAmount} }}</p>
                    <p *ngIf="walletAmount?.hasError('max')">{{ 'MAX_VALIDATION' | translate: {max: maxAmount} }}</p>
                    <p
                      *ngIf="walletAmount?.hasError('pattern')">{{ 'WRONG_NUMBER_FORMAT_VALIDATION' | translate: {max: maxAmount} }}</p>
                  </div>
                </div>
              </div>

              <div class="flex flex-row leading-4">
                <p class="text-[#252525]">{{ 'REMAINING_BALANCE' | translate }}:</p>
                <p class="ml-1 font-semibold">{{ calculateRest() | currency }}</p>
              </div>
            </div>
          </div>

          <button
            *ngIf="walletAccountData && !walletAccountData.isActive && !isLoadingWalletAccount && !isLoadingChangePaymentMethod"
            (click)="activateWallet()"
            class="flex bg-yellow-#FFC35D rounded-3px flex-row items-center font-Lato-Regular font-semibold text-md justify-center px-6 h-11">
            {{ 'ACTIVATE_WALLET' | translate }}
          </button>

          <div *ngIf="isLoadingWalletAccount || isLoadingChangePaymentMethod"
               class="flex flex-1 flex-col font-Lato-Regular h-full justify-center items-end gap-2 animate-pulse">
            <div class="relative w-40 h-5 rounded-full bg-gray-300"></div>
            <div class="flex">
              <div class="relative w-40 h-8 rounded bg-gray-300"></div>
            </div>
            <div class="relative w-40 h-5 rounded-full bg-gray-300"></div>
          </div>
        </div>
        <div *ngIf="(formMarkAsTouched && !isKWalletFundsApplied) || insufficientBalance ||
              (isKWalletFundsApplied && formMarkAsTouched && walletAccountData.balance < total && selectedPaymentVariant === paymentMethodEnum.WALLET)"
             class="absolute bottom-0.5 text-sm text-red-500">
          <p *ngIf="formMarkAsTouched && !isKWalletFundsApplied && !insufficientBalance">
            {{ 'MUST_APPLY_FUNDS' | translate }}
          </p>
          <p *ngIf="((insufficientBalance && selectedPaymentVariant === paymentMethodEnum.WALLET) ||
                    (isKWalletFundsApplied && formMarkAsTouched && walletAccountData.balance < total &&
                    selectedPaymentVariant === paymentMethodEnum.WALLET))">
            {{ 'INSUFFICIENT_FUNDS' | translate }}
          </p>
        </div>
      </div>
    </ng-template>

    <!-- Template para el componente de tarjetas -->
    <ng-template #Cards>
      <app-payments-cards
        *ngIf="!isLoadingCard && (transactionPaymentActive || combinedPaymentActive) && !isLoadingPaymentMethodConfiguration && !isLoadingPaymentVariantFee"
        [ngClass]="{'mt-5': selectedPaymentVariant === paymentMethodEnum.TRANSACTION }"
        [formMarkAsTouched]="formMarkAsTouched" [showAddCards]="true" [showCardDetails]="true"
        (onCardSelection)="setSelectedCard($event)">
      </app-payments-cards>
    </ng-template>

    <!-- Template para las direcciones de facturacion -->
    <ng-template #BillingAddress>
      <div class="flex flex-col mt-3"
           *ngIf="showBillingAddress && !isLoadingPaymentMethodConfiguration && !isLoadingPaymentVariantFee">
        <div class="flex flex-row justify-between">
          <p class="relative font-Lato-Regular font-semibold text-gray-#363636 text-19px">
            {{ 'BILLING_ADDRESS' | translate }}
          </p>
          <button class="relative flex justify-end items-center" (click)="addAddress(false)"
                  [disabled]="billingAddresses.length"
                  [ngClass]="{'cursor-not-allowed': billingAddresses.length, 'opacity-50': billingAddresses.length}">
            <fa-icon
              class="relative flex justify-center items-center  bg-yellow-#FF6D03 rounded-full w-7 h-7 p-1 text-white"
              [icon]="faAdd">
            </fa-icon>
          </button>
        </div>

        <p class="relative font-Lato-Regular text-gray-#727272 mt-1">
          {{ 'SELECT_ADDRESS_THAT_MATCHES_YOUR_CARD_OR_PAYMENT_METHOD' | translate }}
        </p>

        <div class="relative us:pb-12 lg-landscape:pb-7 flex flex-1 flex-col">
          <div
            *ngIf="!isLoadingAddress && !isLoadingBillingAddress && !isLoadingPaymentMethodConfiguration && !isLoadingPaymentVariantFee"
            [ngClass]="{'border border-red-500': billingAddresses.length === 0}"
          >
            <app-new-checkout-address-manager [formMarkAsTouched]="formMarkAsTouched"
                                              [errorText]="'SELECT_A_BILLING_ADDRESS'" [addresses]="billingAddresses"
                                              (onAddressSelect)="setSelectedBillingAddress($event)" (onAddressUpdate)="refreshAddresses()"
                                              [selectedAddress]="selectedBillingAddress" [isShipping]="false"></app-new-checkout-address-manager>
          </div>
          <div
            class="relative us:bottom-1 lg-landscape:bottom-0.5 text-sm text-red-500 mt-2"
            *ngIf="!isLoadingAddress && !isLoadingBillingAddress && !isLoadingPaymentMethodConfiguration && !isLoadingPaymentVariantFee && billingAddresses.length === 0"
          >
            <p>{{ 'BILLING_ADDRESS_REQUIRED' | translate }}</p>
          </div>
          <!--      SKELETON-->
          <div
            *ngIf="isLoadingAddress || isLoadingBillingAddress || isLoadingPaymentMethodConfiguration || isLoadingPaymentVariantFee"
            class="flex flex-1 flex-col h-100px mt-10px border border-gray-#D6D6D6 animate-pulse">
            <div class="flex h-full flex-row p-4 bg-white">
              <div class="flex flex-row">
                <div class="relative w-5 h-5 rounded-full bg-gray-300"></div>
              </div>

              <div class="flex flex-col flex-1 ml-3">
                <div class="relative w-60 h-4 rounded-full bg-gray-300"></div>
                <div class="relative rounded w-full h-5 my-2 bg-gray-300"></div>
                <div class="relative w-40 h-4 rounded-full bg-gray-300"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #TransferPayment>
      <div class="relative pb-7">
        <app-new-cart-multi-currencies
          [formMarkAsTouched]="formMarkAsTouched" [total]="total" [order]="order!"
          [selectedPaymentVariantId]="selectedPaymentVariant!"
          [isLoadingChangePaymentMethod]="isLoadingChangePaymentMethod"
          (changeCurrenciesSelected)="changeCurrenciesSelected($event)"
          [isSaving]="isLoadingSaving"
          (isLoadingCurrenciesAvailability)="setIsLoadingCurrenciesAvailability($event)"></app-new-cart-multi-currencies>
      </div>
    </ng-template>

    <ng-template #MiTransfer>
      <div class="relative pb-7">
        <app-new-cart-multi-currencies
          [formMarkAsTouched]="formMarkAsTouched" [total]="total" [order]="order!"
          [selectedPaymentVariantId]="selectedPaymentVariant!"
          [isLoadingChangePaymentMethod]="isLoadingChangePaymentMethod"
          (changeCurrenciesSelected)="changeCurrenciesSelected($event)"
          [isSaving]="isLoadingSaving"
          (isLoadingCurrenciesAvailability)="setIsLoadingCurrenciesAvailability($event)"></app-new-cart-multi-currencies>
      </div>
    </ng-template>

    <ng-template #EmailPaymentRequest>
      <div class="relative mt-4 w-full">
        <p class="relative w-full text-left font-Lato-Bold text-base text-gray-#252525">
          {{ 'PAYMENT_REQUEST_LINK' | translate }}</p>
        <p class="relative w-full text-left font-Lato-Regular text-sm text-gray-#5d5d5d mt-4">
          {{ 'PAYMENT_REQUEST_LINK_LONG_TEXT' | translate }}</p>
        <form [formGroup]="payment_link_form" action="" class="relative grid grid-cols-1 gap-y-1 py-5
                w-full
                landscape:w-full">
          <div class="relative w-full h-auto py-5">
            <input placeholder="{{'EMAIL_FORM_OPTIONAL' | translate}}" class="relative text-gray-#727272 bg-sky-50 font-Lato-Regular w-full
                              border-none focus:outline-none active:outline-none focus:ring-0 active:ring-0
                              us:h-47px us:rounded-3px us:pl-18px us:text-base
                              lg-landscape:h-47px lg-landscape:rounded-3px lg-landscape:pl-4 lg-landscape:text-base"
                   type="text" formControlName="email">
            <div *ngIf="payment_link_form?.invalid && (payment_link_form?.dirty || payment_link_form?.touched)"
                 class="absolute bottom-0.5 text-base text-red-500 text-11px leading-4">
              <p *ngIf="payment_link_form.controls['email'].hasError('pattern')"> {{
                  'WRONG_FORMAT' |
                    translate
                }}
              </p>
            </div>
          </div>

          <textarea placeholder="{{'OPTIONAL_MESSAGE' | translate}}" class="relative text-gray-#727272 bg-sky-50 font-Lato-Regular w-full
                          border-none focus:outline-none active:outline-none focus:ring-0 active:ring-0 min-h-88px
                          us:rounded-3px us:pl-18px us:text-base
                          lg-landscape:rounded-3px lg-landscape:pl-4 lg-landscape:text-base" type="text"
                    formControlName="message"></textarea>
        </form>


      </div>
    </ng-template>

    <ng-template #MlcCup>
      <div class="relative pb-7">
        <app-new-cart-multi-currencies [formMarkAsTouched]="formMarkAsTouched" [total]="total" [order]="order!"
                                       [selectedPaymentVariantId]="selectedPaymentVariant!"
                                       [isLoadingChangePaymentMethod]="isLoadingChangePaymentMethod"
                                       (changeCurrenciesSelected)="changeCurrenciesSelected($event)"
                                       [isSaving]="isLoadingSaving"
                                       (isLoadingCurrenciesAvailability)="setIsLoadingCurrenciesAvailability($event)"
        ></app-new-cart-multi-currencies>
      </div>
    </ng-template>

    <div class="flex flex-col gap-2 lg:gap-5 mt-3 justify-center items-center">
      <span class="text-blue-600 font-Lato-Regular text-[16px] hover:cursor-pointer text-center" (click)="clearSelectedPayment()" *ngIf="selectedPaymentVariant !== null">
        {{ 'SELECT_ANOTHER_PAYMENT_METHOD' | translate }}
      </span>
      <button
        [disabled]="((selectedPaymentVariant === paymentMethodEnum.TRANSFER_MOVIL || selectedPaymentVariant === paymentMethodEnum.MI_TRANSFER) && (isLoadingCurrenciesAvailability || !selectedCurrencyAvailable)) || isLoadingChangePaymentMethod || isLoadingSaving || (!selectedCard && isLoadingWalletAccount) ||
                    isLoadingBillingAddress || isLoadingPaymentMethodConfiguration || isLoadingPaymentVariantFee || !selectedPaymentVariant || !selectedPaymentVariantObject"
        [ngClass]="{'opacity-50': ((selectedPaymentVariant === paymentMethodEnum.TRANSFER_MOVIL || selectedPaymentVariant === paymentMethodEnum.MI_TRANSFER) && (isLoadingCurrenciesAvailability || !selectedCurrencyAvailable)) || isLoadingChangePaymentMethod || isLoadingSaving || (!selectedCard && isLoadingWalletAccount) ||
                    isLoadingBillingAddress || isLoadingPaymentMethodConfiguration || isLoadingPaymentVariantFee || !selectedPaymentVariant || !selectedPaymentVariantObject,
                    'cursor-not-allowed ': ((selectedPaymentVariant === paymentMethodEnum.TRANSFER_MOVIL || selectedPaymentVariant === paymentMethodEnum.MI_TRANSFER) && (isLoadingCurrenciesAvailability || !selectedCurrencyAvailable)) || isLoadingChangePaymentMethod || isLoadingSaving ||  (!selectedCard && isLoadingWalletAccount) ||
                    isLoadingBillingAddress || isLoadingPaymentMethodConfiguration || isLoadingPaymentVariantFee || !selectedPaymentVariant || !selectedPaymentVariantObject}"
        class="mt-3 rounded-full bg-[#FF6D03] py-2 text-[19px] text-white w-full
        flex justify-center" (click)="submit()">
        <div class="text-center">{{ 'PAY' | translate }}</div>
        <fa-icon *ngIf="isLoadingSaving"
                 class="relative flex justify-center items-center animate-spin bg-yellow-#FF6D03 rounded-full w-9 h-9 text-xl text-white"
                 [icon]="faSpinner">
        </fa-icon>
      </button>
      <button class="relative font-bold flex justify-center items-center text-yellow-#FF6D03 bg-white border-2 border-yellow-#FF6D03 rounded-full hover:bg-orange-100
        font-Lato-Regular text-sm w-full h-10 mt-1" (click)="logout()">
        <img class="relative w-7 fill-yellow-#FF6D03" src="../../../../../assets/imgs/icon-arrowleft.svg" alt="">
        {{'RETURN' | translate}}
      </button>
    </div>
  </div>

</div>
