import {
  Component,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild
} from "@angular/core";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {
  faAdd,
  faCheck,
  faCheckCircle,
  faCreditCard,
  faSignOut,
  faSpinner,
  faTools,
  faWarning
} from '@fortawesome/free-solid-svg-icons';
import {
  CardNewIntegration,
  Country,
  PaymentMethodConfiguration,
  PaymentMethodConfigurationVariant,
  StrapiResponseData,
  WalletAccount,
} from "../../shared/models";
import {Order} from "../../shared/models/store-order.model";
import {filter, Subject, Subscription} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {NotificationService} from "../../shared/services/notification.service";
import {CARD_PREDICTORS_INFO, CARD_TYPES} from "../../shared/enum/card-info.enum";
import {AddressItem, GetBillingAddressResponse} from "../../shared/models/new-address.model";
import {PaymentMethodsEnum} from "../../shared/enum/payment-methods.enum";
import {CurrencyAvailableInterface} from "../multi-currencies/multi-currencies.component";
import {NewCheckoutEnum} from "../../shared/enum/checkout.enum";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {CommonModule, isPlatformBrowser, Location} from "@angular/common";
import {CheckoutService} from "../../shared/services/checkout.service";
import {CartService} from "../../shared/services/cart.service";
import {AuthService} from "../../shared/services/auth.service";
import {CardService} from "../../shared/services/card.service";
import {WalletService} from "../../shared/services/wallet.service";
import {NewCartService} from "../../shared/services/newcart.service";
import {NewAddressService} from "../../shared/services/new-address.service";
import {CreatePaymentMethodComponent} from "../create-payment-method/create-payment-method.component";
import {NewAddAddressComponent} from "../new-add-address/new-add-address.component";
import {ErrorsEnum} from "../../shared/enum/errors.enum";
import {AcceptTermsAndConditionsComponent} from "../accept-terms-and-conditions/accept-terms-and-conditions.component";
import {OnfidoStartVerificationComponent} from "../onfido-start-verification/onfido-start-verification.component";
import {QrStaffPaymentComponent} from "../qr-staff-payment/qr-staff-payment.component";
import {PaymentsCardsComponent} from "../payments-cards/payments-cards.component";
import {
  NewCheckoutAddressManagerComponent
} from "../new-checkout-address-manager/new-checkout-address-manager.component";
import {NewCartMultiCurrenciesComponent} from "../new-cart-multi-currencies/new-cart-multi-currencies.component";
import {
  NewCartExternalPaymentResponse,
  NewCartTropiPayExternalPaymentResponse
} from "../../shared/models/new-cart-external-payment.model";
import {PaymentRoutesEnum} from "../../shared/enum/payment-routes.enum";
import {LanguageService} from "../../shared/services/language.service";
import {CaptchaModalComponent} from "../../shared/components/captcha-modal/captcha-modal.component";
import {NotificationModalComponent} from "../../shared/components/notification-modal/notification-modal.component";
import {OrderTypeEnum} from "../../shared/enum/order-type.enum";

const K_WALLET_TYPE = 'Spree::Gateway::KwalletGateway2';

@Component({
  selector: 'app-payments',
  standalone: true,
  imports: [
    CommonModule,
    FaIconComponent,
    TranslateModule,
    ReactiveFormsModule,
    QrStaffPaymentComponent,
    PaymentsCardsComponent,
    NewCheckoutAddressManagerComponent,
    NewCartMultiCurrenciesComponent,
  ],
  templateUrl: './payments.component.html',
  styleUrl: './payments.component.css'
})
export class PaymentsComponent implements OnInit, OnDestroy {
  @Output() next = new EventEmitter<boolean>();
  @Output() isBolsaTransfer = new EventEmitter<boolean>();
  @Output() isLoaded = new EventEmitter<void>();
  @Input() isBolsaTransferInput: boolean = false;
  @Input() total: number = 0;
  @Input() paymentRoute: string = '';
  @Input() orderId: string = '';
  @Input() orderNumber: string = '';
  @Input() paymentBreakdown: any;
  @Input() redirect: any;
  @Input() userPaymentRequest: any;
  @ViewChild('section') section: any;
  token: string | null = null;
  faCreditCard = faCreditCard;
  formGroup: FormGroup = new FormGroup({
    wallet_amount: new FormControl(0
      // {
      //   validators: [
      //     Validators.required,
      //     Validators.min(this.minAmount),
      //     Validators.max(this.maxAmount),
      //   ]
      // }
    ),
  });
  minAmount: number = 0.1;
  maxAmount: number = 0;
  languageServiceSubscription!: Subscription;
  formCard: FormGroup = new FormGroup({
    expiration: new FormControl(null, Validators.required),
    name: new FormControl(null, Validators.required),
    number: new FormControl(null, [
      Validators.required,
      Validators.pattern(/^[0-9\s]*$/)
    ]),
    verification_value: new FormControl(null, [
      Validators.required,
      Validators.pattern(/^[0-9]*$/),
      Validators.minLength(3),
      Validators.maxLength(4)
    ]),
    cc_type: new FormControl(null, Validators.required),
    bill_address_attributes: new FormGroup({
      firstname: new FormControl(null, Validators.required),
      lastname: new FormControl(null, Validators.required),
      address1: new FormControl(null, Validators.required),
      address2: new FormControl(null),
      city: new FormControl(null, Validators.required),
      phone: new FormControl(null, Validators.required),
      zipcode: new FormControl(null, Validators.required),
      state_name: new FormControl('FL', Validators.required),
      country_iso: new FormControl('US', Validators.required),
    }),
  });

  formMarkAsTouched: boolean = false;
  isRunningGetAllAddresses: boolean = false;

  // isLoadingPaymentMethod: boolean = true;
  isLoadingChangePaymentMethod: boolean = false;
  // isLoadingCreditCard: boolean = true;
  isLoadingCard: boolean = true;
  selectedNewCard: number = 0;
  isLoadingSaving: boolean = false;

  originalOrder: Order | undefined;
  availablePaymentMethods: any[] = [];
  selectedPayMethodIndex: number = 0;
  faSpinner = faSpinner;
  faCheck = faCheck;
  wrongDate: boolean = false;
  lang: string | undefined;
  creditCards: CardNewIntegration[] = [];
  selectedCard: CardNewIntegration | null = null;
  cardPredictors = CARD_PREDICTORS_INFO;
  cardLogo = '';
  largeMaxLength: number = 19;
  // PAYMENTS
  order: Order | undefined;


  isLoadingWalletAccount: boolean = false;
  isInitWalletAccount: boolean = true;
  walletAccountData: WalletAccount = {
    isActive: false,
    publicAddress: '',
    userId: '',
    isBlocked: false,
    balance: 0,
    pendingBalance: 0,
    reservedBalance: 0
  };
  isKWalletFundsApplied: boolean = false;
  isNotShowKwallet: boolean = false;

  kwalletFounds: number = 0;
  creditCard: number = 0;

  destroy$: Subject<boolean> = new Subject<boolean>();

  showBillingAddress = false;
  isLoadingAddress: boolean = true;
  isLoadingBillingAddress: boolean = false;
  selectedBillingAddress: AddressItem | undefined;
  faAdd = faAdd;
  addresses: AddressItem[] = [];
  billingAddresses: AddressItem[] = [];
  billableCountries: Country[] = [];

  isLoadingPaymentMethodConfiguration: boolean = true;
  paymentMethodConfiguration: PaymentMethodConfiguration | undefined = undefined;
  paymentMethodConfigurationVariants: PaymentMethodConfigurationVariant[] | undefined;
  paymentMethodEnum = PaymentMethodsEnum;
  isLoadingPaymentVariantFee: boolean = true;

  transactionPaymentActive: boolean = false;
  walletPaymentActive: boolean = false;
  combinedPaymentActive: boolean = false;

  transferMovilActive: boolean = false;
  isTransferMovilApplied: boolean = false;
  transferMovilShowAddress: boolean = false;

  miTransferMovilActive: boolean = false;
  isMiTransferMovilApplied: boolean = false;
  miTransferMovilShowAddress: boolean = false;

  tropiPayActive: boolean = false;
  isTropiPayApplied: boolean = false;
  tropiPayShowAddress: boolean = false;

  transactionPaymentShowAddress: boolean = false;
  walletPaymentShowAddress: boolean = false;
  combinedPaymentShowAddress: boolean = false;

  paymentToken: string = '';
  insufficientBalance: boolean = false;

  selectedPaymentVariant: any = null;
  selectedPaymentVariantObject: PaymentMethodConfigurationVariant | undefined;
  isUpdatingCard: boolean = false;
  faCheckCircle = faCheckCircle;

  statuses = {
    completed: 'completed',
    expired: 'expired',
  };

  form: FormGroup = new FormGroup({
    txnInfo: new FormControl(null, Validators.required),
    observations: new FormControl(null),
    qrValue: new FormControl(null, Validators.required),
    storeName: new FormControl(null, Validators.required),
  });

  isDeletingPromotion: boolean = false;
  isDeletingIndex: number | null = null;
  isDeletingProduct = false;

  orderType: OrderTypeEnum = OrderTypeEnum.STORE_ORDER;

  isLoadingCurrenciesAvailability: boolean = false;
  selectedCurrencyAvailable!: CurrencyAvailableInterface | null;
  is_human: boolean = false;
  termsAndConditions: string = '';
  agreementQuery: any;

  protected readonly faTools = faTools;
  protected readonly faSignOut = faSignOut;
  readonly faWarning = faWarning;

  payment_link_form: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
    message: new FormControl(null)
  });

  constructor(
    private checkoutService: CheckoutService,
    private cardService: CardService,
    private cartService: CartService,
    private walletService: WalletService,
    private authService: AuthService,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private newCartService: NewCartService,
    private newAddressService: NewAddressService,
    private zone: NgZone,
    private location: Location,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {

  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.authService.isAuthenticated()) {
        this.getData();
      } else {
        this.authService.currentTokenSubject
          .pipe(
            takeUntil(this.destroy$),
            filter(token => token?.access_token !== '')
          )
          .subscribe(token => {
            this.getData();
          });
      }
    }
  }


  getData() {
    const paymentMethod = JSON.parse(<string>localStorage.getItem(NewCheckoutEnum.PAYMENT));
    const paymentCardsStorage = JSON.parse(<string>localStorage.getItem(NewCheckoutEnum.PAYMENT_CARDS));
    if (paymentMethod && paymentMethod != '' && paymentMethod.data) {
      this.selectedPayMethodIndex = paymentMethod.selectedPayMethodIndex;
      if (paymentCardsStorage && paymentCardsStorage != '') {
        this.creditCards = paymentCardsStorage;
        this.selectedCard = paymentMethod.selectedCard;
        this.selectedNewCard = paymentMethod.selectedNewCard;
      }

      this.transferMovilActive = paymentMethod.transferMovilActive;
      this.isTransferMovilApplied = paymentMethod.isTransferMovilApplied;
      this.transferMovilShowAddress = paymentMethod.transferMovilShowAddress;
      this.miTransferMovilActive = paymentMethod.miTransferMovilActive;
      this.isMiTransferMovilApplied = paymentMethod.isMiTransferMovilApplied;
      this.miTransferMovilShowAddress = paymentMethod.miTransferMovilShowAddress;
      this.tropiPayActive = paymentMethod.tropiPayActive;
      this.isTropiPayApplied = paymentMethod.isTropiPayApplied;
      this.tropiPayShowAddress = paymentMethod.tropiPayShowAddress;
      this.insufficientBalance = paymentMethod.insufficientBalance;
      this.originalOrder = <Order>{...this.order};
      this.isLoadingCard = false;
      this.isKWalletFundsApplied = paymentMethod.isKWalletFundsApplied;
      // this.getWalletAccount(false);
      this.walletAccountData = paymentMethod.walletAccountData;
      this.isLoadingWalletAccount = paymentMethod.isLoadingWalletAccount;
      this.formCard.patchValue({
        expiration: paymentMethod?.formCard?.expiration,
        name: paymentMethod?.formCard?.name,
        number: paymentMethod?.formCard?.number,
        verification_value: paymentMethod?.formCard?.verification_value,
        cc_type: paymentMethod?.formCard?.cc_type
      });

      this.isLoadingPaymentMethodConfiguration = paymentMethod.isLoadingPaymentMethod;
      this.paymentMethodConfiguration = paymentMethod.paymentMethodConfiguration;
      this.paymentMethodConfigurationVariants = paymentMethod.paymentMethodConfigurationVariants;
      this.selectedBillingAddress = paymentMethod.selectedBillingAddress;
      this.billableCountries = paymentMethod.billableCountries;
      this.addresses = paymentMethod.addresses;
      this.combinedPaymentActive = paymentMethod.combinedPaymentActive;
      this.combinedPaymentShowAddress = paymentMethod.combinedPaymentShowAddress;
      this.selectedPaymentVariant = paymentMethod.selectedPaymentVariant;
      this.selectedPaymentVariantObject = paymentMethod.selectedPaymentVariantObject;

      this.activateMethodVariables(this.paymentMethodConfiguration!, false);
    } else {
      this.getPaymentMethodConfiguration();
    }
  }

  setStorage(data?: object) {
    if (!this.isLoadingCard) {
      const commonData = {
        selectedNewCard: this.selectedNewCard,
        selectedPayMethodIndex: this.selectedPayMethodIndex,
        isKWalletFundsApplied: this.isKWalletFundsApplied,
        walletAccountData: this.walletAccountData,
        isLoadingWalletAccount: this.isLoadingWalletAccount,
        isLoadingPaymentMethodConfiguration: this.isLoadingPaymentMethodConfiguration,
        paymentMethodConfiguration: this.paymentMethodConfiguration,
        paymentMethodConfigurationVariants: this.paymentMethodConfigurationVariants,
        selectedBillingAddress: this.selectedBillingAddress,
        billableCountries: this.billableCountries,
        addresses: this.addresses,
        combinedPaymentActive: this.combinedPaymentActive,
        combinedPaymentShowAddress: this.combinedPaymentShowAddress,
        transferMovilActive: this.transferMovilActive,
        miTransferMovilActive: this.miTransferMovilActive,
        isTransferMovilApplied: this.isTransferMovilApplied,
        isMiTransferMovilApplied: this.isMiTransferMovilApplied,
        transferMovilShowAddress: this.transferMovilShowAddress,
        tropiPayActive: this.tropiPayActive,
        isTropiPayApplied: this.isTropiPayApplied,
        tropiPayShowAddress: this.tropiPayShowAddress,
        paymentToken: this.paymentToken,
        selectedPaymentVariant: this.selectedPaymentVariant,
        selectedPaymentVariantObject: this.selectedPaymentVariantObject,
        insufficientBalance: this.insufficientBalance,
        // selectedCurrencyAvailable: this.selectedCurrencyAvailable,
        selectedCurrencyAvailable: this.paymentMethodEnum.TRANSFER_MOVIL === this.selectedPaymentVariant || this.paymentMethodEnum.MI_TRANSFER === this.selectedPaymentVariant || this.selectedPaymentVariantObject?.walletBehaviour === 3 ? this.selectedCurrencyAvailable : undefined,
        data: true
      };
      const localStorageData = data ? {...commonData, ...data} : {...commonData};
      localStorage.setItem(NewCheckoutEnum.PAYMENT, JSON.stringify(localStorageData));
    }
  }

  setSelectedCard(data: any) {
    this.selectedCard = data.selectedCard;
  }

  setShowBillingAddressByPaymentMethod(paymentMethod: number) {
    this.showBillingAddress = false;
    switch (paymentMethod) {
      case this.paymentMethodEnum.TRANSACTION:
        if (this.transactionPaymentActive) {
          this.showBillingAddress = this.transactionPaymentShowAddress;
        } else {
          this.showBillingAddress = false;
        }
        break;
      case this.paymentMethodEnum.WALLET:
        if (this.walletPaymentActive) {
          this.showBillingAddress = this.walletPaymentShowAddress;
        } else {
          this.showBillingAddress = false;
        }
        break;
      case this.paymentMethodEnum.TRANSACTION_AND_WALLET:
        if (this.combinedPaymentActive) {
          this.showBillingAddress = this.combinedPaymentShowAddress;
        } else {
          this.showBillingAddress = false;
        }
        break;
      case this.paymentMethodEnum.TRANSFER_MOVIL:
        if (this.transferMovilActive) {
          this.showBillingAddress = this.transferMovilShowAddress;
        } else {
          this.showBillingAddress = false;
        }
        break;
      case this.paymentMethodEnum.MI_TRANSFER:
        if (this.miTransferMovilActive) {
          this.showBillingAddress = this.miTransferMovilShowAddress;
        } else {
          this.showBillingAddress = false;
        }
        break;
      case this.paymentMethodEnum.TROPI_PAY:
        if (this.tropiPayActive) {
          this.showBillingAddress = this.tropiPayShowAddress;
        } else {
          this.showBillingAddress = false;
        }
        break;
      default: {
        this.showBillingAddress = false;
        break;
      }
    }

    if (this.showBillingAddress) {
      this.loadAddresses();
    }
  }

  private activateMethodVariables(paymentMethodConfiguration: PaymentMethodConfiguration, selectPaymentMethodConfigurationVariant: boolean = true) {
    if (paymentMethodConfiguration) {
      this.paymentMethodConfigurationVariants = [];
      for (let it of paymentMethodConfiguration?.allowedPaymentVariants) {
        if (!it.disabled && it.visible) {
          this.paymentMethodConfigurationVariants.push(it);
        }
        switch (it.paymentVariantId) {
          case this.paymentMethodEnum.TRANSACTION:
            this.transactionPaymentActive = !it.disabled;
            this.transactionPaymentShowAddress = it.showBillingToSelect;
            break;
          case this.paymentMethodEnum.WALLET:
            this.walletPaymentActive = !it.disabled;
            this.walletPaymentShowAddress = it.showBillingToSelect;
            break;
          case this.paymentMethodEnum.TRANSACTION_AND_WALLET:
            this.combinedPaymentActive = !it.disabled;
            this.combinedPaymentShowAddress = it.showBillingToSelect;
            break;
          case this.paymentMethodEnum.TRANSFER_MOVIL:
            this.transferMovilActive = !it.disabled;
            this.transferMovilShowAddress = it.showBillingToSelect;
            break;
          case this.paymentMethodEnum.MI_TRANSFER:
            this.miTransferMovilActive = !it.disabled;
            this.miTransferMovilShowAddress = it.showBillingToSelect;
            break;
          case this.paymentMethodEnum.TROPI_PAY:
            this.tropiPayActive = !it.disabled;
            this.tropiPayShowAddress = it.showBillingToSelect;
            break;
        }
      }
      if (this.userPaymentRequest && this.paymentMethodConfigurationVariants) {
        this.selectedPaymentVariant = this.paymentMethodEnum.PAYMENT_REQUEST;
        let paymentRequest = this.paymentMethodConfigurationVariants?.find((it) => it?.paymentVariantId == this.selectedPaymentVariant);
        if( paymentRequest ) this.selectPaymentMethodConfigurationVariant(paymentRequest);
      }
      if (!this.selectedPaymentVariantObject) {
        this.isLoadingPaymentVariantFee = false;
      } else {
        this.selectPaymentMethodConfigurationVariant(this.selectedPaymentVariantObject, true, selectPaymentMethodConfigurationVariant);
      }
      this.isLoaded.emit();
    }
  }

  getPaymentMethodConfiguration(activateMethodVariables: boolean = true) {
    this.isLoadingPaymentMethodConfiguration = true;
    this.checkoutService.getPaymentMethodConfiguration(
      this.languageService.selectedLanguage.value, this.paymentRoute
    ).subscribe((response: PaymentMethodConfiguration) => {
      this.paymentMethodConfiguration = response;
      if (activateMethodVariables) {
        this.activateMethodVariables(this.paymentMethodConfiguration);
      }
      this.isLoadingPaymentMethodConfiguration = false;
      this.isLoadingCard = false;
      this.isLoaded.emit();
    });
  }

  applyTransferMovil(isApply: boolean) {
    this.isTransferMovilApplied = isApply;
    if (isApply) {
      this.transfermovilFundsApplied(isApply, this.paymentMethodEnum.TRANSFER_MOVIL);
      this.setShowBillingAddressByPaymentMethod(this.paymentMethodEnum.TRANSFER_MOVIL);
    } else {
      this.order!.externalAmount = 0;
      this.originalOrder = <Order>{...this.order};
    }
    this.setStorage();
  }

  applyMiTransferMovil(isApply: boolean) {
    this.isMiTransferMovilApplied = isApply;
    if (isApply) {
      this.transfermovilFundsApplied(isApply, this.paymentMethodEnum.MI_TRANSFER);
      this.setShowBillingAddressByPaymentMethod(this.paymentMethodEnum.MI_TRANSFER);
    } else {
      this.order!.externalAmount = 0;
      this.originalOrder = <Order>{...this.order};
    }
    this.setStorage();
  }

  applyTropiPay(isApply: boolean) {
    this.isTropiPayApplied = isApply;
    if (isApply) {
      this.tropiPayFundsApplied(isApply);
      this.setShowBillingAddressByPaymentMethod(this.paymentMethodEnum.TROPI_PAY);
    } else {
      this.order!.externalAmount = 0;
      this.originalOrder = <Order>{...this.order};
    }
    this.setStorage();
  }

  applyFunds(applyFund: boolean, selectedPaymentVariant: number) {
    if (applyFund) {
      this.isTransferMovilApplied = false;
      this.isMiTransferMovilApplied = false;
      this.isTropiPayApplied = false;
      if (this.combinedPaymentActive && selectedPaymentVariant === this.paymentMethodEnum.TRANSACTION_AND_WALLET) {

        const formData = this.formGroup.getRawValue();
        this.applyWalletFounds(formData.wallet_amount);
        this.walletFundsApplied(applyFund);
        this.setShowBillingAddressByPaymentMethod(this.paymentMethodEnum.TRANSACTION_AND_WALLET);
      } else {
        if (this.walletPaymentActive && this.selectedPaymentVariant === this.paymentMethodEnum.WALLET && this.walletAccountData?.balance >= this.total) {
          this.insufficientBalance = false;
          this.applyWalletFounds();
          this.walletFundsApplied(applyFund);
        } else if (this.walletPaymentActive && this.selectedPaymentVariant === this.paymentMethodEnum.WALLET && this.walletAccountData?.balance < this.total) {
          this.insufficientBalance = true;
        }
      }
    } else {
      if (this.combinedPaymentActive && selectedPaymentVariant === this.paymentMethodEnum.TRANSACTION_AND_WALLET) {
        this.showBillingAddress = false;
        this.setShowBillingAddressByPaymentMethod(this.paymentMethodEnum.TRANSACTION);
      }
      this.kwalletFounds = 0;
      this.creditCard = 0;
      this.walletFundsApplied(applyFund);
    }
    this.setStorage();
  }

  private walletFundsApplied(applyFund: boolean) {
    this.isKWalletFundsApplied = applyFund;
    this.setStorage();
  }

  private transfermovilFundsApplied(applyFund: boolean, transfermovil: PaymentMethodsEnum = this.paymentMethodEnum.TRANSFER_MOVIL) {
    if (transfermovil === this.paymentMethodEnum.TRANSFER_MOVIL) {
      this.isTransferMovilApplied = applyFund;
    } else {
      this.isMiTransferMovilApplied = applyFund;
    }
    this.setStorage();
  }

  private tropiPayFundsApplied(applyFund: boolean) {
    this.isTropiPayApplied = applyFund;
    this.setStorage();
  }

  private applyWalletFounds(funds?: number) {
    this.kwalletFounds = 0;
    this.creditCard = 0;

    if (this.selectedPaymentVariant === this.paymentMethodEnum.TRANSACTION_AND_WALLET) {
      if (funds) {
        this.kwalletFounds = funds;
      }
      this.creditCard = this.creditCard ? this.creditCard - this.kwalletFounds : this.total - this.kwalletFounds;
    } else {
      if (this.selectedPaymentVariant === this.paymentMethodEnum.WALLET) {
        this.kwalletFounds = this.walletAccountData?.balance >= this.total ? this.total : this.walletAccountData?.balance;
      }
      if (
        this.transactionPaymentActive &&
        (this.selectedPaymentVariant === this.paymentMethodEnum.TRANSACTION_AND_WALLET || this.selectedPaymentVariant === this.paymentMethodEnum.TRANSACTION)
      ) {
        this.creditCard = this.creditCard ? this.creditCard - this.kwalletFounds! : 0;
      }
    }
    this.setStorage();
  }

  activateWallet() {
    if (!this.walletAccountData.isActive) {
      this.setStorage();
      this.dialog.open(AcceptTermsAndConditionsComponent, {
        disableClose: false,
      }).afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: ((data: any) => {
            if (data?.modified) {
              this.getWalletAccount(true);
            } else if (!data?.modified && data?.error) {
              this.notificationService.showAndSubscribe(data?.error, 'VERIFY', 'CLOSE')
                .afterClosed()
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                  next: ((data: any) => {
                    if (data) {
                      this.verifyUserIdentity();
                    }
                  }),
                  error: (err => {
                    throw new Error(err);
                  })
                });
            }
          }),
          error: (err => {
            throw new Error(err);
          })
        })
    }
  }

  verifyUserIdentity() {
    const useNewCart = this.authService.getCurrentUser().useNewCart;
    const checkoutFragment = useNewCart ? 'cart/checkout/payment' : 'cart/checkout';
    let dialoRef = this.dialog.open(OnfidoStartVerificationComponent, {
      disableClose: false,
      // position: { top: '30px' },
      data: {
        // redirectUrl: `${environment.base_route}/${this.getSelectedZone?.area_selected?.toLowerCase() || 'cu'}/${checkoutFragment}`
      }
    });
    dialoRef.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ((response: any) => {
          if (response?.modified) {
            location.href = response.workflowRunUrl;
          }
        }),
        error: (err => {
          throw new Error(err);
        })
      })
  }

  calculateRest(): number {
    if (this.walletAccountData?.balance > this.kwalletFounds) {
      return this.walletAccountData?.balance - this.kwalletFounds;
    } else {
      return 0;
    }
  }

  isWalletSelected(): boolean {
    return this.availablePaymentMethods[this.selectedPayMethodIndex]?.type == K_WALLET_TYPE;
  }

  changeSelectedPayMethod(index: number) {
    if (!this.isLoadingSaving) {
      this.selectedPayMethodIndex = index;
      this.isKWalletFundsApplied = false;
    }
  }

  getWalletAccount(isApply = true) {
    this.isLoadingWalletAccount = true;
    this.walletService.getAccount().subscribe({
      next: (walletData: WalletAccount | null) => {
        if (walletData) {
          this.walletAccountData = walletData;
          if (isApply) {
            this.applyFunds(true, this.selectedPaymentVariant!);
          }
        } else {
          this.isNotShowKwallet = true;
        }
        this.isInitWalletAccount = false;
        this.isLoadingWalletAccount = false;
        this.setStorage();
      },
      error: (error) => {
        this.isLoadingWalletAccount = false;
      }
    });
  }

  backStepper() {
    this.next.emit(false);
  }

  async submit() {
    this.formMarkAsTouched = true;
    let selectedPaymentMethod;
    if (this.paymentRoute === PaymentRoutesEnum.GIFT_CARDS)
      this.orderType = OrderTypeEnum.GIFT_CARD_ORDER;
    else if (this.paymentRoute === PaymentRoutesEnum.CUBACEL)
      this.orderType = OrderTypeEnum.CUBACEL_ORDER;
    if (this.paymentMethodConfigurationVariants && this.paymentMethodConfigurationVariants?.length) {
      selectedPaymentMethod = this.paymentMethodConfigurationVariants.find(it => it?.paymentVariantId === this.selectedPaymentVariant && this.selectedPaymentVariantObject?.walletBehaviour === it?.walletBehaviour);
    }
    if ( selectedPaymentMethod?.paymentVariantId ) {
      if (selectedPaymentMethod!.paymentVariantId === this.paymentMethodEnum.TRANSFER_MOVIL
        || selectedPaymentMethod!.paymentVariantId === this.paymentMethodEnum.MI_TRANSFER
        || (selectedPaymentMethod!.paymentVariantId === this.paymentMethodEnum.WALLET
          && this.selectedPaymentVariantObject?.walletBehaviour === 3)) {
        const dialogRef = this.dialog.open(CaptchaModalComponent);
        const token = await dialogRef.afterClosed().toPromise();
        if (token)
          this.captchaCapture(token);
        else {
          this.notificationService.showAndSubscribe('INVALID_CAPTCHA', 'CLOSE');
          return;
        }
      }
      if (!this.isLoadingChangePaymentMethod && !this.isLoadingSaving && !this.isLoadingBillingAddress && !this.isLoadingPaymentMethodConfiguration && !this.isLoadingPaymentVariantFee && !this.isLoadingWalletAccount &&
        selectedPaymentMethod && (!selectedPaymentMethod.showBillingToSelect || selectedPaymentMethod.showBillingToSelect && this.selectedBillingAddress) &&
        (
          (selectedPaymentMethod.paymentVariantId === this.paymentMethodEnum.WALLET && this.isKWalletFundsApplied && this.walletAccountData.balance >= this.total) ||
          (selectedPaymentMethod.paymentVariantId === this.paymentMethodEnum.TRANSACTION_AND_WALLET && this.isKWalletFundsApplied && this.selectedCard) ||
          (selectedPaymentMethod.paymentVariantId === this.paymentMethodEnum.TRANSACTION && this.selectedCard) ||
          (selectedPaymentMethod.paymentVariantId === this.paymentMethodEnum.TRANSFER_MOVIL && this.isTransferMovilApplied) ||
          (selectedPaymentMethod.paymentVariantId === this.paymentMethodEnum.MI_TRANSFER && this.isMiTransferMovilApplied) ||
          (selectedPaymentMethod.paymentVariantId === this.paymentMethodEnum.TROPI_PAY && this.isTropiPayApplied) ||
          (selectedPaymentMethod.paymentVariantId === this.paymentMethodEnum.STAFF_PAYMENT && this.form.valid) ||
          (
            selectedPaymentMethod.paymentVariantId !== this.paymentMethodEnum.WALLET &&
            selectedPaymentMethod.paymentVariantId !== this.paymentMethodEnum.TRANSACTION_AND_WALLET &&
            selectedPaymentMethod.paymentVariantId !== this.paymentMethodEnum.TRANSFER_MOVIL &&
            selectedPaymentMethod.paymentVariantId !== this.paymentMethodEnum.TRANSACTION &&
            selectedPaymentMethod.paymentVariantId !== this.paymentMethodEnum.STAFF_PAYMENT
          )
        )
      ) {
        this.isLoadingSaving = true;
        const total: number = Math.round(Number(Number(this.total).toFixed(2)) * 100);
        const walletAmount: number = this.isKWalletFundsApplied ? Number(Number(this.kwalletFounds! * 100).toFixed(2)) : 0;
        const externalAmount: number = this.isTransferMovilApplied || this.isMiTransferMovilApplied || this.isTropiPayApplied ? Number(Number(this.total! * 100).toFixed(2)) : 0;

        let billingAddress: AddressItem;

        try {
          if ((this.transferMovilActive && this.selectedPaymentVariant === this.paymentMethodEnum.TRANSFER_MOVIL) || (this.miTransferMovilActive && this.selectedPaymentVariant === this.paymentMethodEnum.MI_TRANSFER)) {
            billingAddress = this.selectedBillingAddress!;
          } else if (this.tropiPayActive && this.selectedPaymentVariant === this.paymentMethodEnum.TROPI_PAY) {
            billingAddress = this.selectedBillingAddress!;
          } else {
            if (this.combinedPaymentActive && this.selectedPaymentVariant === this.paymentMethodEnum.TRANSACTION_AND_WALLET) {
              billingAddress = <AddressItem><unknown>await this.cardService.getCardBillingAddress(this.selectedCard!.id);
            } else {
              if (this.transactionPaymentActive && this.selectedPaymentVariant === this.paymentMethodEnum.TRANSACTION) {
                billingAddress = <AddressItem><unknown>await this.cardService.getCardBillingAddress(this.selectedCard!.id);
              }
              if (this.walletPaymentActive && this.total && this.selectedPaymentVariant === this.paymentMethodEnum.WALLET) {
                billingAddress = this.selectedBillingAddress!;
              }
            }
          }
        } catch (e) {
          console.log(e);
        }

        const paymentIntentionData: any = {
          paymentCardId: this.selectedCard?.id,
          totalAmount: total,
          walletAmount: walletAmount,
          externalAmount: externalAmount,
          paymentRoute: this.paymentRoute,
          paymentVariantId: this.selectedPaymentVariant,
          kwalletTierId: this.selectedCurrencyAvailable ? this.selectedCurrencyAvailable?.walletTierId : null
        };

        if (this.selectedPaymentVariantObject?.walletBehaviour === 3) {
          paymentIntentionData['currencyExchangeRate'] = this.selectedCurrencyAvailable?.displayExchangeRate;
        }
        if (this.isTransferMovilApplied || this.isMiTransferMovilApplied) {
          this.isBolsaTransfer.emit(true);
          paymentIntentionData['externalAmount'] = total;
        } else {
          this.isBolsaTransfer.emit(false);
        }
        if (this.isTropiPayApplied) {
          paymentIntentionData['externalAmount'] = total;
        }
        if (selectedPaymentMethod.paymentVariantId === this.paymentMethodEnum.STAFF_PAYMENT) {
          const formData = this.form.getRawValue();
          paymentIntentionData['extraValues'] = {
            QrValue: formData.qrValue,
            StoreName: formData.storeName,
            TxnInfo: formData.txnInfo,
            Observations: formData.observations,
          };
          paymentIntentionData['paymentCardId'] = null;
        }
        if (selectedPaymentMethod.paymentVariantId === this.paymentMethodEnum.PAYMENT_REQUEST) {
          const formData = this.payment_link_form.getRawValue();
          this.payment_link_form.controls['message'].value === null ?
            paymentIntentionData['extraValues'] = {
              PayerUserEmail: formData.email,
            } :
            paymentIntentionData['extraValues'] = {
              PayerUserEmail: formData.email,
              UserPaymentRequestMessage: formData.message,
            };
          paymentIntentionData['paymentCardId'] = null;
        }
        if (isPlatformBrowser(this.platformId)) {
          const fingerPrint = this.cartService.getFingerPrintFromSession();
          if (fingerPrint) {
            paymentIntentionData['fingerprintTraceDto'] = fingerPrint ? {
              visitorId: fingerPrint ? fingerPrint.visitorId : null,
              requestId: fingerPrint ? fingerPrint.requestId : null
            } : null;
            this.paymentIntention(paymentIntentionData, billingAddress!);
          } else {
            this.checkoutService.getFingerPrint().then((fingerPrint: any) => {
              paymentIntentionData['fingerprintTraceDto'] = fingerPrint ? {
                visitorId: fingerPrint ? fingerPrint.visitorId : null,
                requestId: fingerPrint ? fingerPrint.requestId : null
              } : null;
              this.paymentIntention(paymentIntentionData, billingAddress);
            });
          }
        }
      }
    }
  }

  paymentIntention(paymentIntentionData: any, billingAddress: AddressItem) {
    this.checkoutService.paymentIntention(paymentIntentionData).subscribe({
      next: async (response: any) => {
        if (!response.success) {
          this.isLoadingSaving = false;
          if (response.responseCode === ErrorsEnum.PAYMENT_CARD_NEEDS_REVALIDATION) {
            this.openRevalidateCardModal();
          } else if (response?.responseCode === "PendingPaymentRequest") {
            this.dialog.open(NotificationModalComponent, {
              // backdropClass: '',
              disableClose: true,
              width: 'auto',
              data: {
                msg: response.message,
                btn_ok_text: 'CLOSE',
                btn_cancel_txt: '',
                redirect: '',
                queryParams: ''
              },
            }).afterClosed().subscribe( resp => {
              const url = new URL(location.href);
              url.pathname = 'payment-requests';
              url.searchParams.delete('code');
              url.searchParams.delete('total');
              location.href = url.href;
            });

            // const paramMap = this.activatedRoute.snapshot.queryParamMap;
            // this.notificationService.showAndSubscribe(response.message, 'CLOSE', '', false, '/payment-requests', location.search);
          } else {
            this.notificationService.showAndSubscribe(response.message, 'CLOSE');
          }
        } else {
          this.paymentToken = response.data.paymentToken;
          const localStorageData = {
            selectedNewCard: this.selectedNewCard,
            selectedPayMethodIndex: this.selectedPayMethodIndex,
            selectedCard: this.selectedCard,
            isKWalletFundsApplied: this.isKWalletFundsApplied,
            walletAccountData: this.walletAccountData,
            isLoadingWalletAccount: this.isLoadingWalletAccount,
            isLoadingPaymentMethodConfiguration: this.isLoadingPaymentMethodConfiguration,
            paymentMethodConfiguration: this.paymentMethodConfiguration,
            paymentMethodConfigurationVariants: this.paymentMethodConfigurationVariants,
            bill_address_attributes: billingAddress,
            selectedBillingAddress: this.selectedBillingAddress,
            billableCountries: this.billableCountries,
            addresses: this.addresses,
            transferMovilActive: this.transferMovilActive,
            miTransferMovilActive: this.miTransferMovilActive,
            isTransferMovilApplied: this.isTransferMovilApplied,
            isMiTransferMovilApplied: this.isMiTransferMovilApplied,
            transferMovilShowAddress: this.transferMovilShowAddress,
            tropiPayActive: this.tropiPayActive,
            isTropiPayApplied: this.isTropiPayApplied,
            tropiPayShowAddress: this.tropiPayShowAddress,
            paymentToken: this.paymentToken,
            selectedPaymentVariant: this.selectedPaymentVariant,
            selectedPaymentVariantObject: this.selectedPaymentVariantObject,
            selectedCurrencyAvailable: this.selectedCurrencyAvailable,
            data: true
          };
          this.setStorage(localStorageData);
          if (this.selectedPaymentVariant === this.paymentMethodEnum.TROPI_PAY) {
            let errorUrl = decodeURIComponent(window.location.href);
            if(window.location.href.includes('localhost')){
              const url = new URL(decodeURIComponent(window.location.href));
              errorUrl = "https://dev.payments.katapulk.com" + url.pathname + url.search;
              console.log('ERROR', errorUrl);
            }
            const data = {
              amount: Math.round(Number(Number(this.total).toFixed(2)) * 100),
              paymentVariantId: this.selectedPaymentVariant,
              paymentToken: this.paymentToken,
              redirect: this.redirect,
              paymentRoute: this.paymentRoute,
              orderId: this.orderId,
              errorUrl: errorUrl,
              orderTypeId: this.orderType.valueOf()
            }

            this.newCartService.createTropiPayPayment(data).subscribe({
              next: (response: NewCartTropiPayExternalPaymentResponse) => {
                if (response.success) {
                  if (response.data && response.data.alreadyCompleted) {
                    this.navigateNextTropiPay();
                  } else {
                    const localStorageData = {
                      isTropiPay: true,
                      paymentToken: this.paymentToken,
                      extraData: response.data.extraData,
                      broketTopic: response.data.broketTopic,
                      userMessage: response.data.userMessage,
                      expireInMinutes: response.data.expireInMinutes,
                      specialIntructions: response.data.specialIntructions,
                    };
                    this.setStorageData(localStorageData, NewCheckoutEnum.TROPI_PAY_DATA);
                    this.navigate(response.data.extraData.ShortUrl);
                  }
                  this.originalOrder = <Order>{...this.order};
                  this.newCartService.newCartOrderSubject$.next(this.order);
                } else {
                  this.notificationService.showAndSubscribe(response.message, 'CLOSE');
                }
                this.isLoadingSaving = false;
              },
              error: (error) => {
                this.isLoadingSaving = false;
                this.notificationService.showAndSubscribe(error.error.message, 'CLOSE');
                this.backToPay();
                const localStorageData = {
                  isTropiPay: false,
                };
                this.setStorageData(localStorageData, NewCheckoutEnum.TROPI_PAY_DATA);
                this.next.emit(false);
              }
            });
          } else if (this.isBolsaTransferInput) {
            const data = {
              paymentVariantId: this.selectedPaymentVariant,
              amount: Math.round(Number(Number(this.total).toFixed(2)) * 100),
              paymentToken: this.paymentToken,
              externalCurrencyAmount: this.selectedCurrencyAvailable?.externalCurrencyAmount,
              externalCurrencyType: this.selectedCurrencyAvailable?.externalCurrencyTypeId,
              captchaToken: this.token,
              orderId: this.orderId,
              // testResponseCode: "PaymentError"  // Success, PaymentError, UnavailableCurrencyAmount
            };

            this.newCartService.startExternalPayment(data).subscribe({
              next: (response: NewCartExternalPaymentResponse) => {
                if (response.success) {
                  if (response.data && response.data.alreadyCompleted) {
                    this.navigateNext();
                  } else {
                    const localStorageData = {
                      isBolsaTransfer: true,
                      paymentToken: this.paymentToken,
                      extraData: response.data.extraData,
                      broketTopic: response.data.broketTopic,
                      userMessage: response.data.userMessage,
                      expireInMinutes: response.data.expireInMinutes,
                      specialIntructions: response.data.specialIntructions,

                    };
                    this.setStorageData(localStorageData, NewCheckoutEnum.BOLSA_TRANSFER_DATA);
                    this.next.emit(true);
                  }
                  // this.order!.selectedCurrencyAvailable = this.selectedCurrencyAvailable;
                  this.originalOrder = <Order>{...this.order};
                  this.newCartService.newCartOrderSubject$.next(this.order);
                } else {
                  this.notificationService.showAndSubscribe(response.message, 'CLOSE');
                }
                this.isLoadingSaving = false;
              },
              error: (error) => {
                this.isLoadingSaving = false;
                this.notificationService.showAndSubscribe(error.error.message, 'CLOSE');
                this.backToPay();
                const localStorageData = {
                  isBolsaTransfer: false,
                };
                this.setStorageData(localStorageData, NewCheckoutEnum.BOLSA_TRANSFER_DATA);
                this.isBolsaTransfer.emit(false);
                this.next.emit(false);
              }
            });
          } else {
            await this.authService.signOff();
            if (this.paymentRoute == PaymentRoutesEnum.GIFT_CARDS) {
              this.authService.navigateToApp({
                redirect: '/gift-cards',
                pr: this.paymentRoute,
                paymentToken: this.paymentToken,
                paymentVariantId: this.selectedPaymentVariant,
              }, this.orderId);
            } else if (this.paymentRoute == PaymentRoutesEnum.CUBACEL) {
                this.authService.navigateToApp({
                  redirect: '/cubacel',
                  paymentToken: this.paymentToken,
                  paymentVariantId: this.selectedPaymentVariant
                }, this.orderId);
            } else if(this.paymentRoute == PaymentRoutesEnum.EMAIL_PAYMENT_REQUEST){
              this.authService.navigateToApp({
                redirect: '/profile/payment-requests',
                paymentToken: this.paymentToken,
                paymentVariantId: this.selectedPaymentVariant
              })
            } else {
              this.authService.navigateToApp({
                redirect: '/cu/cart/checkout/start',
                paymentToken: this.paymentToken,
                paymentVariantId: this.selectedPaymentVariant
              });
            }
          }
        }
      },
      error: (error) => {
        this.isLoadingSaving = false;
      }
    });
  }

  setStorageData(data: object, key: string) {
    if (!this.isLoadingCard) {
      localStorage.setItem(key, JSON.stringify(data));
    }
  }

  captchaCapture(token: any) {
    this.token = token;
    this.is_human = true;
    let el: HTMLElement = this.section?.nativeElement;
    el?.click();
  }

  setNumberWithoutSpace(targetValue: string): string {
    let result = targetValue?.split(' ').join('');
    return result;
  }

  setCardType(targetValue: string) {
    let num = targetValue[0];
    if (targetValue.length == 1) {
      this.verifyCard(num);
    } else {
      for (let k = 1; k < targetValue.length; k++) {
        num += targetValue[k];
        this.verifyCard(num);
      }
    }
  }

  verifyCard(digits: any): any {
    let rangeStart;
    let rangeEnd;
    let number;
    for (let index = 0; index < this.cardPredictors.length; index++) {
      for (let k = 0; k < this.cardPredictors[index].startWith.length; k++) {
        if (this.cardPredictors[index].startWith[k].includes('-')) {
          rangeStart = this.cardPredictors[index].startWith[k]?.split('-')[0];
          rangeEnd = this.cardPredictors[index].startWith[k]?.split('-')[1];
          if (digits >= rangeStart && digits <= rangeEnd) {
            return this.setData(index);
          }
        }
      }
      for (let j = 0; j < digits.length; j++) {
        if (j == 0) {
          number = digits[j];
        } else {
          number += digits[j];
        }
        if (this.cardPredictors[index].startWith.indexOf(number) !== -1) {
          return this.setData(index);
        }
      }
    }
  }

  setData(index: number) {
    const type = this.cardPredictors[index].type;
    // @ts-ignore
    const i = CARD_TYPES[`${type}`];
    const maxLength = Math.max(...this.cardPredictors[index].lenghts);
    this.largeMaxLength = maxLength % 4 == 0 ? maxLength + (Math.floor(maxLength / 4) - 1) : maxLength + Math.floor(maxLength / 4);
    this.formCard.patchValue({
      cc_type: CARD_TYPES[i]
    });
    this.cardLogo = `../../../../assets/imgs/create-card/${CARD_TYPES[i]}.svg`;
  }

  keyupNumberCard(e: any) {
    const numberWithoutSpace = this.setNumberWithoutSpace(e.target.value);
    this.setCardType(numberWithoutSpace);
    const data = this.validateFormat(e.target.value);
    if (data.length && data.length % 5 == 0) {
      const chartIndex = data.length - 1;
      if (data.charAt(chartIndex) != ' ') {
        const chartData = data.charAt(chartIndex);
        const firstFours = data.substr(0, chartIndex);
        e.target.value = firstFours + ' ' + chartData;
      } else {
        e.target.value = data.substr(0, chartIndex);
      }
    } else {
      e.target.value = data;
    }
  }

  validateFormat(data: string): string {
    let result = data?.split(' ').join('');

    if (result.length > 4) {
      const chopInto = Math.ceil(result.length / 4);

      const arraySubstr = [];
      for (let i = 0; i < chopInto; i++) {
        if (i == chopInto - 1) {
          arraySubstr.push(result.substr(i * 4));
        } else {
          arraySubstr.push(result.substr(i * 4, 4));
        }
      }
      let dataResult = '';
      arraySubstr.forEach((it, index) => {
        if (index == 0) {
          dataResult = it;
        } else {
          dataResult = dataResult + ' ' + it;
        }
      });
      return dataResult;
    }

    return result;
  }

  keyupExpirationCard(e: any) {
    const date = this.formCard.get('expiration')?.value;
    const month = date?.split('/')[0];
    const year = date?.split('/')[1];
    const actualMonth = new Date().getUTCMonth() + 1;
    const actualYear = new Date().getUTCFullYear().toString().substring(2);

    this.wrongDate = (month > 12 || month < actualMonth && year <= actualYear) || year < actualYear;

    if (e.target.value.length == 2 && e?.key?.toString() !== 'Backspace') {
      e.target.value += '/';
    }
  }

  allowNumbersAndSlash(e: any): boolean {
    let result = false;
    const charCode = e.charCode;
    if (charCode != 0) {
      if (charCode < 48 || charCode > 57) {
        e.preventDefault();
        result = false;
      } else {
        result = true;
      }
    }
    return result;
  }

  get name() {
    return this.formCard.get('name');
  }

  get number() {
    return this.formCard.get('number');
  }

  get verification_value() {
    return this.formCard.get('verification_value');
  }

  get expiration() {
    return this.formCard.get('expiration');
  }

  ngOnDestroy(): void {
    this.languageServiceSubscription?.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  openRevalidateCardModal() {
    let dialoRef = this.dialog.open(CreatePaymentMethodComponent, {
      disableClose: true,
      // position: { top: '30px' },
      data: {paymentCardId: this.selectedCard?.id, isRevalidate: true, isRevalidateCheckout: true},
    });
    dialoRef.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        if (response != 'close') {
          // this.getCards();
          this.submit();
        }
      })
  }

  // Billing Address logic -- Start
  isLoadingAgreement: boolean = true;

  addAddress(isShippingAddress: boolean) {
    this.openAddAddressModal(isShippingAddress);
  }

  openAddAddressModal(isShippingAddress: boolean, data?: any) {
    let dialoRef = this.dialog.open(NewAddAddressComponent, {
      disableClose: false,
      // position: { top: '30px' },
      data: {isModeEdit: !!data, data, isShippingAddress},
    });
    dialoRef.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ((response: any) => {
          if (response?.modified) {
            this.getBillingAddresses();
          }
        }),
        error: (err => {
          throw new Error(err);
        })
      })
  }

  setSelectedBillingAddress(address: AddressItem) {
    this.selectedBillingAddress = address;
    this.setStorage();
  }

  getBillingAddresses() {
    this.isLoadingBillingAddress = true;
    this.newAddressService.getBillingAddresses({}).subscribe({
      next: (addressesResponse: GetBillingAddressResponse) => {
        this.billingAddresses = addressesResponse.data ? [addressesResponse.data] : [];
        this.selectedBillingAddress = addressesResponse.data;
        this.setLoadersBillingToFalse();
      },
      error: () => {
        this.setLoadersBillingToFalse();
      },
      complete: () => {
        this.setLoadersBillingToFalse();
      }
    });
  }

  private setLoadersBillingToFalse() {
    this.isLoadingBillingAddress = false;
    this.isRunningGetAllAddresses = false;
    this.isLoadingAddress = false;
  }

  loadAddresses() {
    this.isLoadingAddress = true;
    this.getBillingAddresses();
  }

  refreshAddresses() {
    this.isLoadingAddress = true;
    this.getBillingAddresses()
  }

  selectPaymentMethodConfigurationVariant(paymentVariant: PaymentMethodConfigurationVariant, loading: boolean = true, cleanPaymentValues: boolean = true) {
    this.selectedCurrencyAvailable = null;
    if (paymentVariant && !this.isUpdatingCard) {
      this.form.reset();
      this.isLoadingChangePaymentMethod = true;
      this.isLoadingCurrenciesAvailability = false;
      this.formMarkAsTouched = false;
      if (cleanPaymentValues) {
        this.insufficientBalance = false;
      }
      this.isUpdatingCard = true;
      this.isLoadingPaymentVariantFee = loading;
      this.selectedPaymentVariantObject = paymentVariant;
      const paymentVariantId: number = paymentVariant.paymentVariantId;
      this.selectedPaymentVariant = paymentVariant.paymentVariantId;
      this.setShowBillingAddressByPaymentMethod(paymentVariantId);
      this.setStorage();

      if (cleanPaymentValues) {
        this.cleanPaymentValues(paymentVariantId);
      }
      this.setStorage();
      if (paymentVariantId === this.paymentMethodEnum.TRANSFER_MOVIL) {
        this.applyTransferMovil(true);
      }
      if (paymentVariantId === this.paymentMethodEnum.TROPI_PAY) {
        this.applyTropiPay(true);
      }
      if (paymentVariantId === this.paymentMethodEnum.MI_TRANSFER) {
        this.applyMiTransferMovil(true);
      }
      if (paymentVariantId === this.paymentMethodEnum.TRANSACTION_AND_WALLET) {
        if (this.total > 1) {
          this.maxAmount = Number(Number(this.total - 1).toFixed(2));
          if (this.maxAmount > 1) {
            this.minAmount = 1;
          } else {
            this.minAmount = 0.1;
          }
        } else {
          this.minAmount = 0.1;
          this.maxAmount = this.total;
        }
        this.formGroup.get('wallet_amount')?.setValidators([
          Validators.required,
          Validators.min(this.minAmount),
          Validators.max(this.maxAmount),
          Validators.pattern(/^(\d{1, 3}(\\,\d{3})*|(\d+))(\.\d{1,2})?$/)
        ]);
        this.getWalletAccount(false);
      } else {
        this.formGroup.get('wallet_amount')?.reset(0);
        if (paymentVariantId === this.paymentMethodEnum.WALLET) {
          this.getWalletAccount(true);
          // this.applyWalletFounds();
        }
      }
      this.isLoadingPaymentVariantFee = false;
      this.isLoadingChangePaymentMethod = false;
      this.isUpdatingCard = false;

      if (this.selectedPaymentVariantObject?.agreementQuery){
        this.agreementQuery = this.selectedPaymentVariantObject?.agreementQuery;
        this.checkoutService.getAgreementPaymentVariant(this.agreementQuery, this.languageService.selectedLanguage.value)
          .pipe(takeUntil(this.destroy$))
          .subscribe((response: StrapiResponseData) => {
            this.termsAndConditions = response.attributes?.Text;
            this.isLoadingAgreement = false;
          });
      }

      this.setShowBillingAddressByPaymentMethod(paymentVariantId);
    }
  }

  private cleanPaymentValues(paymentVariantId: number | undefined) {
    switch (paymentVariantId) {
      case this.paymentMethodEnum.TRANSACTION:
        this.isTransferMovilApplied = false;
        this.isMiTransferMovilApplied = false;
        this.isTropiPayApplied = false;
        this.isKWalletFundsApplied = false;
        break;
      case this.paymentMethodEnum.WALLET:
        this.isTransferMovilApplied = false;
        this.isMiTransferMovilApplied = false;
        this.isTropiPayApplied = false;
        this.isKWalletFundsApplied = false;
        this.applyFunds(true, paymentVariantId);
        break;
      case this.paymentMethodEnum.TRANSACTION_AND_WALLET:
        this.isKWalletFundsApplied = false;
        this.isTransferMovilApplied = false;
        this.isMiTransferMovilApplied = false;
        this.isTropiPayApplied = false;
        break;
      case this.paymentMethodEnum.TRANSFER_MOVIL:
        this.isKWalletFundsApplied = false;
        break;
      case this.paymentMethodEnum.TROPI_PAY:
        this.isKWalletFundsApplied = false;
        break;
    }
  }

  openAgreementModal() {
    this.router.navigate(['/terms-and-conditions']);
  }

  get walletAmount() {
    return this.formGroup.get('wallet_amount');
  }

  setIsLoadingCurrenciesAvailability(value: boolean) {
    this.isLoadingCurrenciesAvailability = value;
  }

  changeCurrenciesSelected(currency: CurrencyAvailableInterface) {
    this.selectedCurrencyAvailable = currency;
  }

  navigateNext() {
    this.newCartService.cleanStorage();
    this.newCartService.removeCartValuesInStorage();
    this.router.navigate([`/profile`]);
  }

  navigateNextTropiPay() {
    const url = new URL(this.redirect);
    this.newCartService.cleanStorage();
    this.authService.navigateToApp({
      redirect: '/cu/cart/checkout/start',
      paymentToken: this.paymentToken,
      paymentVariantId: this.paymentMethodEnum.TROPI_PAY
    }, this.orderId);
  }

  navigate(url: string) {
    this.newCartService.cleanStorage();
    this.newCartService.removeCartValuesInStorage();

    this.zone.runOutsideAngular(() => {
      window.location.href = url;
    });
  }

  backToPay() {
    this.next.emit(false);
  }

  async logout() {
    await this.authService.signOff();
    this.authService.navigateToAppExpiredToken()
  }

  clearSelectedPayment() {
    this.selectedPaymentVariant = null;
    this.termsAndConditions = '';
    this.isLoadingAgreement = true;
  }
}
